<div style="height: 85%;">
    <div style="margin-left: 30px; margin-top: 20px; overflow-x: hidden;overflow-y:hidden;">
        <div class="row" >

            <div *ngIf="!activaRetorno" class=" pb-4" >

                <div class="row" style="height: 100px;overflow-x: hidden" *ngIf="_isAdmin" >
                    <div class="col-lg-4 azul "  style="width: 40px; height: 40px; margin-top: 30px;">
                        <img (click)="regresar()" src="../../../assets/img/Back.png" > 
                    </div>
                    <div class="col-lg-4 azul tipografiaHeader" style="margin-top: 35px;margin-left: 50px;" >
                        <span >{{carpetaActual}}</span>
                    </div>
                    <div class="col-lg-4 azul logo" >
                        <img src="../../../assets/img/activos/logo.png"  style="margin-top: -6px;"> 
                    </div>
                </div>

                <div class="row" style="height: 100px;" *ngIf="!_isAdmin">
                    <div class="col-lg-4 azul tipografiaHeader" style="margin-top: 35px;" >
                        <span >{{carpetaActual}}</span>
                    </div>
                    <div class="col-lg-4 azul tipografiaHeader" style="margin-top: 35px;margin-left: 300px;">
                        
                    </div>
                    <div class="col-lg-4 azul logo" >
                        <img src="../../../assets/img/activos/logo.png"   style="margin-top: -3px;"> 
                    </div>
                </div>


            </div> 

            <div *ngIf="activaRetorno" class="pb-4" style="overflow-x: hidden;overflow-y:hidden;">
                <div class="row" style="height: 100px;">
                    <div class="col-lg-4 azul " style="width: 40px; height: 40px; margin-top: 30px;">
                        <img (click)="regresarUser()" src="../../../assets/img/Back.png" > 
                    </div>
                    <div class="col-lg-4 azul tipografiaHeader" style="margin-top: 35px;margin-left: 50px;" *ngIf="documentos">
                        <span class="">Documentos</span>
                    </div>
                    <div class="col-lg-4 azul tipografiaHeader" style="margin-top: 35px;margin-left: 50px;" *ngIf="imagenes">
                        <span class="">Imágenes</span>
                    </div>
                    <div class="col-lg-4 azul tipografiaHeader" style="margin-top: 35px;margin-left: 50px;" *ngIf="videos">
                        <span class="">Videos</span>
                    </div>
                    <div class="col-lg-4 azul logo" >
                        <img  src="../../../assets/img/activos/logo.png" style="margin-top: -6px;"> 
                    </div>
                </div>
            </div> 
        </div>
        
    </div>
    
    <div class="d-grid">
        <div class="bg-light border" style="height: 5px;margin-left: -50px;margin-top: -16px;"></div>
    </div>
    
    <br>
    <div style="margin-left: 30px; overflow-x: hidden;overflow-y:hidden;">            
        <div class="row" *ngIf="!activaRetorno" style="height: 430px;overflow-x: hidden;overflow-y:hidden;">
            <div  class="row justify-content-start">
                <div class="col-lg-4 azul tipografia"  *ngIf="documentos">
                    <img [routerLink]=" ['Documentos'] " src="../../../assets/img/carpeta.png" id="documentos" (click)='getFolderSelect("documentos")' 
                        data-toggle="documentos" data-target="#pdf" routerLinkActive="active" style="width: 90px; height: 90px;"> <br>
                    <span class="">Documentos</span>
                </div>
            </div>
            
            <div  class="row justify-content-start">
                <div class="col-lg-4 azul tipografia" *ngIf="imagenes">
                    <img [routerLink]=" ['Imagenes'] " src="../../../assets/img/carpeta.png" id="imagenes" (click)='getFolderSelect("imagenes")' 
                        data-toggle="imagenes" data-target="#imagenes" routerLinkActive="active" style="width: 90px; height: 90px;"  > <br>
                    <span class="">Imágenes</span>
                </div>
            </div>
            
            <div  class="row justify-content-start">
                <div class="col-lg-4 azul tipografia"*ngIf="videos">
                    <img [routerLink]=" ['Videos'] " src="../../../assets/img/carpeta.png" id="videos" (click)='getFolderSelect("videos")' 
                        data-toggle="videos" data-target="#videos" routerLinkActive="active" style="width: 90px; height: 90px;"  > <br>
                    <span class="">Videos</span>
                </div>
            </div>

        </div>
    </div>  
</div>
<div style="width: 99%;">
    <router-outlet></router-outlet> 
</div>
