
<!-- 
<table class="table" style="margin-left: -30px;width: 100%;height: 100%;">
  <tbody>
    <tr> 
      <td style=" background-color: black;  height: 100%; width: 50%;">
        
        <div class="login">
          <form class="custom-form" method="post">
            <h1>Login</h1>
            <input
              type="email"
              [(ngModel)]="email"
              name="email"
              placeholder="Email"
              required="required"
            />
            <input
              type="password"
              [(ngModel)]="password"
              name="password"
              placeholder="Password"
              required="required"
            />
            <button type="submit" (click)="login()">Log in</button>
          </form>
        </div>
        
      </td>
      <td style="background-image: url(../../../assets/img/login.PNG); height: 100%; width: 50%;">
      
        
      
      </td> 
    </tr>
  </tbody>
</table>
-->

<section class="h-100 w-100 gradient-form" style="background-color: black; margin-left: 0%;">
  <div class="container  h-100 w-100" >
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10" >
        <div class="text-black">
          <div class="row g-0">
            <div class="col-lg-6" style="background-color: #333437; color: white;">
              <div class="card-body p-md-5 mx-md-4">

                <div class="text-center" style="height: 200px;">
                  
                </div>

                <form>
                  <h1>Login</h1>
                  <p>Ingresa los datos solicitados</p>
                  <div class="form-outline mb-4">
                      <input type="email" [(ngModel)]="email" name="email" placeholder="Email" class="form-control"  required="required"/>
                      <label class="form-label" for="email">Usuario</label>
                  </div>
                  <div class="form-outline mb-4">
                      <input type="password" [(ngModel)]="password" name="password" placeholder="Password" class="form-control"  required="required" />
                      <label class="form-label" for="password">Constraseña</label>
                  </div>
                  <div class="text-center pt-1 mb-5 pb-1">
                      <button class="btn btn-primary btn-lg btn-block" style="width: 100%;" (click)="login()"  type="button">Entrar</button>
                  </div>
                  <div class="text-center pt-1 mb-5 pb-1">
                      <a    style="width: 100%; color: #2986B2; cursor: pointer;" (click)="recoveryPass()" >Olvide mi contraseña</a>
                  </div>
                  <div class="d-flex align-items-center justify-content-center pb-4">
                      <div class="col-lg-4 ">
                        <img src="../../../assets/img/logos/latam.png" style="width: 100px; height: 100px;"> 
                      </div>
                      <div class="col-lg-4">
                        <img src="../../../assets/img/logos/pepsico.png" style="width: 100px; height: 100px;"> 
                      </div>
                      <div class="col-lg-4">
                        <img src="../../../assets/img/logos/l.png" style="width: 112px; height: 54.08px;"> 
                      </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center gradient-custom-2" 
            style="background-image: url(../../../assets/img/logos/login.jpg); background-size: 100% 48.8%; background-repeat: no-repeat; background-position: 50% 0; background-size: cover;">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>