import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/usuario';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  currentUser!: Usuario;

  constructor(private userService: UserService){
    console.log("=== AdminComponent constructor ====");
    /*this.userService.currentUser.subscribe((user: Usuario) => {
        this.currentUser=user;
      console.log(" ######## currentUser ########:["+this.currentUser?.nombre+"]");
    });
    */
  }
  ngOnInit(): void {
   
  }

  logout(){
      this.userService.logout();
  }
  
}
