import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleBaseGuard implements CanActivate {

  constructor(private authService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


      
    console.log('G U A R D   E N A B L E');
    if(!this.authService.isAuthenticated) {
      console.log("USUARIO NO ESTA AUTENTICADO");
      this.authService.logout();
      this.router.navigate(['/login']);
      return false;
    }else{
      console.log("========================"); 
      console.log("USUARIO ESTA AUTENTICADO"); 
      const userRole = this.authService.getRol();
      console.log("===== userRole:["+userRole+"] dataRol ["+route.data['role']+"]=====");
      console.log("========================");
      if(route.data['role'] && route.data['role'] !== userRole) {
         return false;
      }
      return true;
    }

  }
  
}
