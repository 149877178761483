import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MEDIA_ROUTES } from './components/media/media.routes';
import { ErrorComponent } from './components/error/error.component';
import { LoginComponent } from './components/login/login/login.component';
import { RoleBaseGuard } from './guard/role-base.guard';
import { RecoverypassComponent } from './components/login/recoverypass/recoverypass.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'recovery',
    component: RecoverypassComponent,
  },
  {
      path: 'principal',
      component: HomeComponent,
      children:MEDIA_ROUTES,
      canActivate:[ RoleBaseGuard],
      data: {
          role: 'ADMIN'
      }
  },
  {
    path: '**',
    component: LoginComponent,
  },
 
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
