
 
 <!--{{ conectado | async }} -->
 <!-- 
 <div class="row">
    <table class="table  tabla-resumen table-striped table-hover table-condensed " >
      <thead class="table-light" >
          <th><h5>#</h5></th>
          <th> <h5>Nombre de carpeta</h5></th>
      </thead>
      <tbody class="table-group-divider" style=" background:transparent;">
       
          <tr *ngFor="let hero of LISTA_CARPETAS  ;let i = index;">
              <td> <h5>  {{ i + 1 }}</h5></td>
              <td>
                <button id="btnInvoicePdf" class="btn button-pdf" aria-label="asdas"> 
                  <img (click)="selectedFolder(hero)" [routerLink]=" [hero]" routerLinkActive="active" src="../../../assets/img/carpeta.png" style="width: 70px; height: 70px;"> 
                  <span style="font-size: 10; font-weight: 500;color: white;"> {{hero}}</span>
                </button>
              </td>
          </tr>
      </tbody>
    </table>
    </div>
-->
<div style="margin-top: 24px;">
  <br>
  <h1 style="color: white;margin-left: 2%;"><small>Carpetas Principales</small></h1> 
  <br>

  <div class="d-grid">
    <div class="bg-light border" style="height: 5px;margin-left: -50px;"></div>
  </div>
  <br>

  <div class="d-flex align-items-center justify-content-center pb-4">
    <div class="col-lg-0 ">
    
    </div>
    
    <div class="col-lg-10">
      <mat-grid-list [cols]="9" gutterSize="6px" rowHeight="3:3"  [responsive]="true">
        <mat-grid-tile *ngFor="let item of LISTA_CARPETAS">
         
          <button id="btnInvoicePdf" class="btn button-pdf" aria-label="asdas"> 
            <img class="carpetas"  (click)="selectedFolder(item)" [routerLink]=" [item]" routerLinkActive="active" src="../../../assets/img/carpeta.png" > <br>
            <span class="tipografia"> {{item}}</span>
          </button>
        
         
        </mat-grid-tile>
      </mat-grid-list>  
    </div>
    
    <div class="col-lg-2">
     
    </div>
  
  </div>

</div>