import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConsumeService } from 'src/app/services/consume.service';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import { ConversionUtils } from 'turbocommons-ts';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import PSPDFKit from 'pspdfkit';
import { RequestFile } from 'src/app/model/requestFile';
import { SelectionModel } from '@angular/cdk/collections';
import { Usuario } from 'src/app/model/usuario';


@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css'],
})
export class PdfComponent implements OnInit {
  src = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  dataSource = new MatTableDataSource<FileTo>();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  displayedColumns: string[] = ['index','file','pdf','select'];
  length:number=0;
  prod: boolean=false;
  listFiles: FileTo[]=[];
  formData: FormData | undefined;
  isLoading : boolean = false;
  imagen: any;
  imagenBlob: any;
  nombreImagen="";
  pdfUrl:any;
  url:any;
  PathReportString:any;
  pathHash;
  @ViewChild('ifrm') myDiv!: ElementRef;
  clickedRows = new Set<FileTo>();
  selection = new SelectionModel<FileTo>(true, []);
  fileRequest!: RequestFile;
  user!:boolean;
  constructor(private consumeService: ConsumeService,
              @Inject(DOCUMENT) document: any,
              private router: Router,
              private userService:UserService,
              private modalService: NgbModal,
              private sanitizer: DomSanitizer) { 

        this.pathHash=document.location.hash.substring(1,document.location.hash.length);
        console.log("pathHash:["+this.pathHash+"]");
        this.getListFilesNames();
        this.userService.setCantUploadFile(true); 

        this.userService._updateTable.subscribe(updateT=>{
          console.log("updateTable=>"+updateT);
          if(updateT){
              this.getListFilesNames();
          }
        });

        if(localStorage.getItem("rol") !==null && localStorage.getItem("rol") === "ADMIN"){
          this.user=true;
        }

  }

  ngOnInit(): void {
      this.paginator._intl.itemsPerPageLabel="Registros por pagina: ";
      this.paginator._intl.nextPageLabel="";
      this.paginator._intl.lastPageLabel="";
      this.paginator._intl.firstPageLabel="";
      this.paginator._intl.previousPageLabel="";
     
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewChecked(): void {
    //console.log("selection :["+this.selection+"]"); 
    console.log("------- ngAfterViewChecked ---------");
    this.fileRequest= new RequestFile;
    let fileSelect:string []=[];
    for (let item of this.selection.selected) {
      console.log(item.fileName);
      fileSelect.push(item.fileName);
    }
    this.fileRequest.path=this.pathHash;
    this.fileRequest.lstFilesname=fileSelect;
    this.userService.setFileRequest(this.fileRequest);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }


  downloadInvoicePDF(pdf:any){
    console.log(pdf);
    const blob = new Blob([this.convertBase64ToBlob(pdf.file)], { type: 'application/pdf' });
    const nameFile = pdf.fileName;
    //saveAs(blob, nameFile);
    //abrir en  navegador el archivo
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }
  
  getListFiles(){
    this.formData = new FormData();
    this.formData?.append("path",this.pathHash);
    this.consumeService.getListFiles(this.formData).subscribe((response)=>{
      console.log("response:["+response);
      try {
        this.prod=true;
        this.listFiles=response.lstFiles;   
        this.dataSource = new MatTableDataSource(this.listFiles);
        this.dataSource.paginator = this.paginator;
        this.length=response.lstFiles.length;
        this.dataSource.data= this.dataSource.data;
      } catch (error) {
        console.log(error);
      }  
    });

  }

  getListFilesNames(){
    this.isLoading =true;
    this.formData = new FormData();
    this.formData?.append("path",this.pathHash);
    this.consumeService.getListFilesNames(this.formData).subscribe((response)=>{
      console.log("response:["+response);
      try {
        this.isLoading =false;
        this.prod=true;
        this.listFiles=response.lstFiles;   
        this.dataSource = new MatTableDataSource(this.listFiles);
        this.dataSource.paginator = this.paginator;
        this.length=response.lstFiles.length;
        this.dataSource.data= this.dataSource.data;
      } catch (error) {
        console.log(error);
      }  
    });

  }

  getFile(file:any,templateRef:TemplateRef<any>){
    this.isLoading=true;
    console.log("file:["+file.fileName)+"]";
     this.formData = new FormData();
    this.formData?.append("path",this.pathHash);
    this.formData?.append("filename",file.fileName);
    console.log(" this.formData:["+ this.formData);
    this.consumeService.getFileByName(this.formData).subscribe((response)=>{
      try{
          console.log("response:["+response);
          this.imagenBlob= new Blob([this.convertBase64ToBlob(response.lstFiles[0].file)], { type: 'application/pdf' });
          this.imagen= `data:image/png;base64,${response.lstFiles[0].file}`;
          this.nombreImagen=response.lstFiles[0].fileName;
          this.isLoading=false;
          const dialog = this.modalService.open(templateRef,{
            size: 'lg', //'sm' | 'md' | 'lg' = 'md'
            windowClass: 'modal-lg',
            centered: true,
            backdrop:'static'
          });
      }catch (error) {
        console.log(error);
      }  
    });
  }

  convertBase64ToBlob(Base64Image: any) {
    const decodedData = window.atob(Base64Image);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return uInt8Array
  }

  regresar(){
    console.log("pathGeneral:["+document.location.pathname)+"]";
    let pathReplace=document.location.pathname.replace("pdf","");
    console.log("pathReplace:["+pathReplace)+"]";
    this.userService.getStatusFolder("documentos");
    this.router.navigate([pathReplace]);
  }

  closeModalService(){
    this.modalService.dismissAll();
  }

  descargarImagen(imagen:any){
    saveAs(this.imagenBlob, this.nombreImagen);
  }

}


export interface FileTo{
  imagen:string;
  file:string;
  fileName:string;

}