<div class='dashboard' >
    <div class="dashboard-nav">
        <header>
          <a href="#" class="brand-logo" [routerLink]="['ventas']"><i class="fas fa-anchor">
              
          </i> 
            <img src="assets/img/pacman.png" style="width: 30px; height: 30px;"> <span>PACMAN</span>
          </a>
        </header>
        
        <nav class="dashboard-nav-list" id="navbarSupportedContent" >
          <button class="btn btn-outline-primary btn-lg" style="width: 100%;" data-toggle="crea" data-target="#crea" routerLinkActive="active" [routerLink]=" ['pdf'] ">pdf<i style="font-size: 20px;"></i>
          </button>
            <a href="#" class="dashboard-nav-item "  [routerLink]="['ventas']"  data-toggle="crea" data-target="#crea1"     routerLinkActive="active"><i class="fas fa-tachometer-alt"></i> ventas </a>
            <a href="#" class="dashboard-nav-item "  [routerLink]="['pdf']"     data-toggle="crea" data-target="#crea2"    routerLinkActive="active"><i class="fas fa-tachometer-alt"></i> DOCUMENTOS </a>
            <a href="#" class="dashboard-nav-item "  [routerLink]="['imagenes']"data-toggle="crea" data-target="#crea3"     routerLinkActive="active"><i class="fas fa-tachometer-alt"></i> IMAGENES </a>
            <a href="#" class="dashboard-nav-item "  [routerLink]="['videos']"  data-toggle="crea" data-target="#crea4"     routerLinkActive="active"><i class="fas fa-tachometer-alt"></i> VIDEOS </a> 
            <div class="nav-item-divider"></div>
            <a href="#" class="dashboard-nav-item"   (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout </a>
        </nav>
    </div>
    <div class='dashboard-app'>
      
        <div class='dashboard-content'>
          <header class='dashboard-toolbar' style="width: 100%;">
              <a href="#!" class="menu-toggle">
                <i class="fas fa-bars">
                  
                </i></a>
              <div class=" ">
                <div class="container" >
                  <div class="row " >
                    
                    <div class="col" style="font-size: 20px; font-weight: 500; margin-top: 5px;">
                      Bienvenido:  
                    </div>
                    <div class="col order-4" style="font-size: 20px; font-weight: 500; margin-top: 5px; width: 100%;">
                  <!--  {{currentUser!.nombre | titlecase}}{{currentUser!.apellidos | slice:0:1}}-->  
                    </div>
                    <div class="col order-5" style="font-size: 20px; font-weight: 500; margin-top: 5px;">
                    <!--   {{currentUser!.rol | slice:0:5}} -->
                      <p>
                      
                      </p>
                    </div>
                   
                   
                  </div>
  
                </div>
              </div>
          </header>
            <div class='container'>
              <!--  
              <div class='card'>
                    <div class='card-header'>
                        <h1>Welcome back Jim</h1>
                    </div>
                    <div class='card-body'>
                        <p>Your account type is: Administrator</p>
                    </div>
              </div>
              --> 
            </div>
        </div>
    </div>
  </div>
  
  
  
  
  
  <div class="jumbotron">
    <div class="container">   
        <router-outlet></router-outlet>
    </div>
  </div>