
<div class="d-flex justify-content-center" >
  <div *ngIf="isLoading" class="loading" style="width: 100px;height:100px;" role="status" >
    <span class="">Cargando espere por favor...</span>
  </div>
</div>
  
<!-- 
<div class="col-lg-12" >
  <div class="row">
      <div class="col-lg-12 azul">
          <button class="btn btn-outline-primary btn-lg" style="width: 20%;" data-toggle="regresar" data-target="#regresar"><i style="font-size: 20px;"></i>
                  <img (click)="regresar()" src="../../../assets/img/regresar.png" style="width: 40px; height: 40px;"> 
          </button>
      </div>
  </div>
</div>
-->
<div class="row " style="font-size: large;" *ngIf="prod">
    <table id="tabla1" mat-table [dataSource]="dataSource"  class="table tabla-resumen table-striped table-hover table-condensed margenTables" #tabla1>
      <div >
      <thead class="table ">
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table " style="margin-left: 200px;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (click)="selection.toggle(row)" style="margin-left: 200px;"></tr>
      </thead>
      </div>
      <tbody class="table  table-group-divider " >
        <tr>
          
          <td class="col-xs-3 max-center">
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef class="table-title">NÚM. DE ARCHIVO</th>
              <td mat-cell *matCellDef="let element; let i = index;" class="table-dato border-left-none">{{i +  1 }}</td>
            </ng-container>
          </td>
          
          <td class="col-xs-3 max-center">
            <ng-container matColumnDef="file">
                  <th mat-header-cell *matHeaderCellDef  class="table-title">NOMBRE DEL ARCHIVO</th>
                  <td mat-cell *matCellDef="let element" class="table-dato border-left-none">{{ element.fileName}}    
                  </td>
            </ng-container>
          </td>
          <td class="col-xs-3 max-center">
            <ng-container matColumnDef="pdf" >
              <th  mat-header-cell *matHeaderCellDef  class="table-title">ABRIR</th>
              <td  mat-cell *matCellDef="let element" class="table-dato border-left-none">
                <button id="btnInvoicePdf" class="btn button-pdf">
                  <img (click)="getFile(element,dialogTemplate)"   src="../../../assets/img/activos/doc.png" style="width: 50px; height:50px;">
                  </button>
              </td>
            </ng-container>
          </td> 
         
           <!-- Checkbox Column -->

           <ng-container matColumnDef="select" *ngIf="user" style="align-items: center; background-color: black; background: fixed;">
                
                <th mat-header-cell *matHeaderCellDef > 
                  <mat-checkbox  color="warn"  (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                
                <td mat-cell *matCellDef="let element"  >
                  <mat-checkbox style="margin-left: 7px;" (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(element) : null"
                                [checked]="selection.isSelected(element)">
                  </mat-checkbox>
                </td>

          </ng-container>

           <ng-container matColumnDef="select" *ngIf="!user" >
                
                <th mat-header-cell *matHeaderCellDef class="table-title"  >  
                  <mat-checkbox style="display: none;" class="example-margin" (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                
                <td mat-cell *matCellDef="let element" class="table-dato border-left-none" >
                  <mat-checkbox style="display: none;" (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(element) : null"
                                [checked]="selection.isSelected(element)">
                  </mat-checkbox>
                </td>

          </ng-container>

      </tr>
      </tbody>
    </table>
   
</div>
<br>
<div class="pagination justify-content-center" style="font-size: 20px;font-weight: 600;">
  
  <mat-paginator class="mat-paginator" #paginator   [pageSize]="5" [length]="length" showFirstLastButtons ></mat-paginator>

</div>
<div class="row row-cols-11 row-cols-md-7 g-8 " style="font-size: large;" *ngIf="!prod" (ngModel)="prod">
    No exiten registros
</div>


<!---
<div class="container" *ngFor="let item of listFiles">
    <button id="btnInvoicePdf" class="btn button-pdf" aria-label="asdas"> 
      <img (click)="downloadInvoicePDF(item)"  src="../../../assets/img/PDF.svg" style="width: 30px; height: 30px;"> <span style="font-size: 10; font-weight: 500;"> {{item.fileName}}</span>
    </button>
</div>

<div class="container">
<table>
  <thead>
      <th>#</th>
      <th>Nombre</th>
  </thead>
  <tbody>
      <tr *ngFor="let hero of listFiles;let i = index;">
          <td>{{ i + 1 }}</td>
          <td>
            <button id="btnInvoicePdf" class="btn button-pdf" aria-label="asdas"> 
              <img (click)="downloadInvoicePDF(hero)"  src="../../../assets/img/PDF.svg" style="width: 30px; height: 30px;"> <span style="font-size: 10; font-weight: 500;"> {{hero.fileName}}</span>
              
            </button>
          </td>
      </tr>
  </tbody>
</table>
</div>



<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource"  class="table tabla-resumen table-content caption-top table-striped table-hover">
    
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef class="table-title"> # </th>
      <td mat-cell *matCellDef="let element; let i = index;" class="table-dato border-left-none">{{i +  1 }}</td>
    </ng-container>

    <ng-container matColumnDef="file">
          <th mat-header-cell *matHeaderCellDef class="table-title">nombre</th>
          <td mat-cell *matCellDef="let element" class="table-dato border-left-none">{{ element.fileName}}    
          </td>
    </ng-container>

    <ng-container matColumnDef="pdf">
      <th mat-header-cell *matHeaderCellDef> Archivo </th>
      <td mat-cell *matCellDef="let element"> 

        <button id="btnInvoicePdf" class="btn button-pdf">
          <img (click)="downloadInvoicePDF(element)" 
            src="../../../assets/img/PDF.svg">
            <img *ngIf="!element.activarDescarga" src="../../../assets/img/pdfDisabled.svg" title="Su factura no esta disponible por el momento, intenta mas tarde.">
          </button>
         

      </td>
    </ng-container>

  
    <ng-container matColumnDef="borrar">
      <th mat-header-cell *matHeaderCellDef> borrar </th>
      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
    </ng-container>

    <ng-container matColumnDef="editar">
      <th mat-header-cell *matHeaderCellDef> editar </th>
      <td mat-cell *matCellDef="let element">  </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>

-->

<ng-template #dialogTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{nombreImagen}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"> 
    <div id="outerContainer">
      <div class="pdf-container">
        <pdf-viewer
          [src]="imagen"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="false"
          [zoom]="1"
          [zoom-scale]="'page-width'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
          style="width: 100%; height: 400px;"
        ></pdf-viewer>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <div class="modal-footer">
        <button  class="btn btn-secondary" style="width: 100px;" (click)="descargarImagen('{{imagen}}')"  mat-button >Descargar</button>
        <button  class="btn btn-secondary" (click)="closeModalService()"  mat-button >Close</button>
    </div>
  </mat-dialog-actions>
</ng-template>