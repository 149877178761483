<div class="jumbotron">
<div class="d-flex justify-content-center" >
  <div *ngIf="isLoading" class="loading" style="width: 100px;height:100px;" role="status" >
    <span class="">Cargando espere por favor...</span>
  </div>
</div>

<div *ngIf="!_isPathLogin" >
    <div class='dashboard' *ngIf="_isAdmin" >
      <div class="dashboard-nav" >
          
          <nav class="dashboard-nav-list" id="navbarSupportedContent" style="height: 100%; overflow-x: hidden;overflow-y:hidden;" >
            <div class="container-fluid">

              <div class="row" style="height: 8rem;">
                <a href="#" class="dashboard-nav-item "  [routerLink]="['principal']" ><i class="fas fa-anchor"></i> 
                  <img src="assets/img/activos/user.png" style="width: 30px; height: 30px;"> 
                </a>
              </div>
              
              <div class="row" style="height:  8rem;">
                <a href="#" class="dashboard-nav-item "  [routerLink]="['principal']"  data-toggle="crea" data-target="#crea1"     routerLinkActive="active">
                  <i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/activos/folder1.png" style="width: 30px; height: 30px;">
                </a>
              </div>
              
              <div class="row" style="height:  8rem;">
                
                <a class="dashboard-nav-item "  *ngIf="!_canUploadFile" data-toggle="crea" data-target="#crea1" routerLinkActive="active">
                  <i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/inactivos/add.png" style="width: 30px; height: 30px; cursor: not-allowed;"> 
                </a>
                
                <a class="dashboard-nav-item "  *ngIf="_canUploadFile" (click)="openModalFile(dialogTemplate)" data-toggle="crea" data-target="#crea1" routerLinkActive="active">
                  <i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/activos/add.png" style="width: 30px; height: 30px; cursor: pointer;"> 
                </a>

              </div>
              
              <div class="row" style="height:  8rem;">
                
                <a class="dashboard-nav-item "  *ngIf="!_canUploadFile" data-toggle="crea" data-target="#crea1" routerLinkActive="active">
                  <i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/inactivos/borrar.png" style="width: 30px; height: 30px; cursor: not-allowed;"> 
                </a>
                
                <a class="dashboard-nav-item "  *ngIf="_canUploadFile" (click)="deleteFile()" data-toggle="crea" data-target="#crea1" routerLinkActive="active">
                  <i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/activos/borrar.png" style="width: 30px; height: 30px; cursor: pointer;"> 
                </a>
              
              </div>
              <div class="nav-item-divider"></div>
              <div class="row" style="height:  8rem;">
                <a href="#" class="dashboard-nav-item"   (click)="logout()"><i class="fas fa-sign-out-alt"></i> 
                  <img src="assets/img/activos/salir.png" style="width: 30px; height: 30px;"> 
                </a>
              </div>
            
            </div>
          </nav>
      </div>
    </div>


    <div class='dashboard' *ngIf="!_isAdmin">
      <div class="dashboard-nav">
          <nav class="dashboard-nav-list" id="navbarSupportedContent" >
            <div class="container">
              <div class="row" style="height: 200px; pointer-events: none;">
                <a href="#" class="dashboard-nav-item "  [routerLink]="['ventas']"  data-toggle="crea" data-target="#crea1"     routerLinkActive="active"><i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/activos/folder1.png" style="width: 30px; height: 30px;">
                </a>
              </div>
              <div class="row" style="height: 200px; pointer-events: none;">
                <a href="#" class="dashboard-nav-item "  [routerLink]="['pdf']"     data-toggle="crea" data-target="#crea2"    routerLinkActive="active"><i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/inactivos/add.png" style="width: 30px; height: 30px;"> 
                </a>
              </div>
              <div class="row" style="height: 200px; pointer-events: none;">
                <a href="#" class="dashboard-nav-item "  [routerLink]="['imagenes']"data-toggle="crea" data-target="#crea3"     routerLinkActive="active"><i class="fas fa-tachometer-alt"></i> 
                  <img src="assets/img/inactivos/borrar.png" style="width: 30px; height: 30px;"> 
                </a>
              </div>
              <div class="nav-item-divider"></div>
            </div>
          </nav>
      </div>
    </div>


    <!-- 
        <div class="jumbotron" style=" background-color: blue; width: 100%; height: 100%; background-image: url('../../../assets/img/fondo.png')">
      <div class="container" >   
          <router-outlet></router-outlet>
      </div>
    </div>
    -->

    <div class="jumbotron" style="overflow-x: visible; margin-left: 200px;" >
      <div class="" >   
          <router-outlet></router-outlet>
      </div>
    </div>

  </div>
  
  <div *ngIf="_isPathLogin">
 
      <div class="" >   
          <router-outlet></router-outlet>
      </div>
    
  </div>


  <ng-template  #dialogTemplate >
    <mat-dialog-content  class="mat-typography">
      <form [formGroup]="productoForm" id="modal1">
        <div class="modal-content">
          <div class="container">
            <div>
              <label for="imagen" class="col-form-label"><h5>Selecciona una archivo</h5></label>
              <input formControlName="archivo" class="form-control form-control-lg" id="imagen" type="file" multiple="multiple" (change)="onFileSelected($event)">
            </div>
            <mat-dialog-actions align="end">
                <div class="modal-footer">
                    <button  class="btn btn-secondary" (click)="closeModalService()"  mat-button >Close</button>
                    <button class="btn btn-success" (click)="onSubmit()" > Guardar </button>
                </div>
            </mat-dialog-actions>
          </div> 
        </div>
      </form>    
    </mat-dialog-content>
  </ng-template>
</div>
