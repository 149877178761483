import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MensajeResponse } from 'src/app/model/mensajeResponse';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-recoverypass',
  templateUrl: './recoverypass.component.html',
  styleUrls: ['./recoverypass.component.css']
})
export class RecoverypassComponent implements OnInit {

  email="";
  sendCorreo=false;
  form: FormGroup = new FormGroup({});
  mensaje:MensajeResponse= new MensajeResponse();
  constructor(private userService:UserService,private formBuilder: FormBuilder,private router: Router) {

    this.form = this.formBuilder.group({
      email: ['', [ Validators.required,
                    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
      }); 
    

      console.log("======= RecoverypassComponent ======== ");
      this.userService._mensajeResponse.subscribe(_sendCorreo=>{
        console.log("======= sendCorreo.subscribe ["+_sendCorreo+"]======== ");
          this.mensaje=_sendCorreo;
          this.email="";
          if(_sendCorreo.codigo == 200 ){
                Swal.fire({
                  title: "¡Felicidades!",
                  text: "El correo se envío satisfactoriamente",
                  icon: "success",
                  confirmButtonColor: '#014FA2',
                });

                this.router.navigate(['./login']);

          }else{
                Swal.fire({
                  title: "Oops...",
                  text: "¡No se pudo enviar el correo! [" + this.mensaje.mensaje+"]",
                  icon: "error",
                  confirmButtonColor: '#014FA2',
                });

                this.router.navigate(['./login']);
          }
      });
   }

  ngOnInit(): void {
  }


  recoveryPass(){
    console.log("======= recoveryPass ======== ");
    this.email=this.form.controls['email'].value;
    this.userService.getgetRecoveryPass(this.email);
    this.form.reset();
    console.log("se envio el corre:["+this.sendCorreo+"]");
  }

  regresar(){
    this.router.navigate(['./login']);
  }

}
