import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FileTo } from '../pdf/pdf.component';
import { ConsumeService } from 'src/app/services/consume.service';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import * as saveAs from 'file-saver';
import { SelectionModel } from '@angular/cdk/collections';
import { RequestFile } from 'src/app/model/requestFile';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  dataSource = new MatTableDataSource<FileTo>();
  dataSourceFile = new MatTableDataSource<FileTo>();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  displayedColumns: string[] = ['index','file','pdf','select'];
  //displayedColumns: string[] = ['index','file','pdf','borrar','editar'];
  length:number=0;
  prod: boolean=false;
  listFiles: FileTo[]=[];
  listFiles2: FileTo[]=[];
  formData: FormData | undefined;
  video: any;
  videoBlob: any;
  nombreVideo="";
  pathHash;
  isLoading : boolean = false;
  clickedRows = new Set<FileTo>();
  selection = new SelectionModel<FileTo>(true, []);
  fileRequest!: RequestFile;
  user:boolean=false;
  constructor(private consumeService: ConsumeService, @Inject(DOCUMENT) document: any,
              private modalService: NgbModal,private router: Router,
              private userService:UserService) { 
   
              this.pathHash=document.location.hash.substring(1,document.location.hash.length);
              console.log("pathHash:["+this.pathHash+"]");
              this.getListFilesNames();
              this.userService.setCantUploadFile(true); 

              this.userService._updateTable.subscribe(updateT=>{
                console.log("updateTable=>"+updateT);
                if(updateT){
                    this.getListFilesNames();
                }
              });   

              if(localStorage.getItem("rol") !==null && localStorage.getItem("rol") === "ADMIN"){
                this.user=true;
              }
  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel="Registros por pagina: ";
    this.paginator._intl.nextPageLabel="";
    this.paginator._intl.lastPageLabel="";
    this.paginator._intl.firstPageLabel="";
    this.paginator._intl.previousPageLabel="";
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
    //console.log("selection :["+this.selection+"]"); 
    console.log("------- ngAfterViewChecked ---------");
    this.fileRequest= new RequestFile;
    let fileSelect:string []=[];
    for (let item of this.selection.selected) {
      console.log(item.fileName);
      fileSelect.push(item.fileName);
    }
    this.fileRequest.path=this.pathHash;
    this.fileRequest.lstFilesname=fileSelect;
    this.userService.setFileRequest(this.fileRequest);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  getListFilesNames(){
    this.isLoading=true;
    console.log("path:["+document.location.pathname)+"]";
    this.formData = new FormData();
    this.formData?.append("path",this.pathHash);
    this.consumeService.getListFilesNames(this.formData).subscribe((response)=>{
      console.log("response:["+response);
      try {
        this.isLoading=false;
        this.prod=true;
        this.listFiles=response.lstFiles;   
        this.dataSource = new MatTableDataSource(this.listFiles);
        this.dataSource.paginator = this.paginator;
        this.length=response.lstFiles.length;
        this.dataSource.data= this.dataSource.data;
      } catch (error) {
        console.log(error);
      }  
    });
  }

  
  getFile(file:any,templateRef:TemplateRef<any>){
    this.isLoading=true;
    console.log("file:["+file.fileName)+"]";
    this.formData = new FormData();
    this.formData?.append("path",this.pathHash);
    this.formData?.append("filename",file.fileName);
    console.log(" this.formData:["+ this.formData);
    this.consumeService.getFileByName(this.formData).subscribe((response)=>{
      console.log("response:["+response);
      try {
        console.log("response.fileName :["+response.lstFiles[0].fileName);
        this.videoBlob = new Blob([this.convertBase64ToBlob(response.lstFiles[0].file)], { type: 'application/img' });
        this.video= `data:image/png;base64,${response.lstFiles[0].file}`;
        this.nombreVideo=response.lstFiles[0].fileName;
        //response.lstFiles[0].imagen=imagen;
        //const nameFile = response.fileTO[0].fileName;
        //saveAs(this.videoBlob, response.lstFiles[0].fileName);
        //abrir en  navegador el archivo
        //const url = URL.createObjectURL(blob);
        //window.open(url, '_blank');
        this.isLoading=false;
        const dialog = this.modalService.open(templateRef,{
          size: 'lg', //'sm' | 'md' | 'lg' = 'md'
          windowClass: 'modal-lg',
          centered: true,
          backdrop:'static'
        });

      } catch (error) {
        console.log(error);
      }  
    });
  }

  
  convertBase64ToBlob(Base64Image: any) {
    const decodedData = window.atob(Base64Image);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return uInt8Array
  }
  
  closeModalService(){
    this.modalService.dismissAll();
  }

  
  regresar(){
    console.log("pathGeneral:["+document.location.pathname)+"]";
    let pathReplace=document.location.pathname.replace("videos","");
    console.log("pathReplace:["+pathReplace)+"]";
    this.userService.getStatusFolder("videos");
    this.router.navigate([pathReplace]);
  }

  descargarVideo(imagen:any){
    saveAs(this.videoBlob, this.nombreVideo);
  }

}
