import { ImagenesComponent } from "./imagenes/imagenes.component";
import { PdfComponent } from "./pdf/pdf.component";
import { VideosComponent } from "./videos/videos.component";
import { Routes } from '@angular/router';

export const MEDIA_ROUTES: Routes = [
    {
        path: 'Documentos',
        component: PdfComponent,
    },
    {
        path: 'Imagenes',
        component: ImagenesComponent,
    },
    {
        path: 'Videos',
        component: VideosComponent,
    },
   
   
];

