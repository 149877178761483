<div class="d-flex justify-content-center" >
    <div *ngIf="isLoading" class="loading" style="width: 100px;height:100px;" role="status" >
      <span class="">Cargando espere por favor...</span>
    </div>
</div>
    
    <!-- 
    <div class="col-lg-12" >
      <div class="row">
          <div class="col-lg-12 azul">
              <button class="btn btn-outline-primary btn-lg" style="width: 20%;" data-toggle="regresar" data-target="#regresar"><i style="font-size: 20px;"></i>
                      <img (click)="regresar()" src="../../../assets/img/regresar.png" style="width: 40px; height: 40px;"> 
              </button>
          </div>
      </div>
    </div>
    -->
    <div class="row" style="font-size: large; " *ngIf="prod">
        <table id="tabla1" mat-table [dataSource]="dataSource"  class="table  tabla-resumen table-striped table-hover table-condensed margenTables" #tabla1>

          <thead class="table-light">
              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table  justify-content-center text-center" > </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (click)="selection.toggle(row)"></tr>
          </thead>
          <tbody class="table-group-divider" >
            <tr>
             
              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef class="table-title">NÚM. DE ARCHIVO</th>
                <td mat-cell *matCellDef="let element; let i = index;" class="table-dato border-left-none">{{i +  1 }}</td>
              </ng-container>
    
              <ng-container matColumnDef="file">
                    <th mat-header-cell *matHeaderCellDef class="table-title">NOMBRE DEL ARCHIVO</th>
                    <td mat-cell *matCellDef="let element" class="table-dato border-left-none">{{ element.fileName}}    
                    </td>
              </ng-container>
    
              <ng-container matColumnDef="pdf" >
                <th  mat-header-cell *matHeaderCellDef class="table-title"> ABRIR</th>
                <td  mat-cell *matCellDef="let element" class="table-dato">
                  <button id="btnInvoicePdf" class="btn button-pdf">
                    <img (click)="getFile(element,dialogTemplate)" src="../../../assets/img/activos/video.png" style="width: 50px; height: 50px;">
                    </button>
                </td>
              </ng-container>
            
               <!-- Checkbox Column -->
                    
                <ng-container matColumnDef="select" *ngIf="user">
                      
                  <th mat-header-cell *matHeaderCellDef class="table-title" > 
                    <mat-checkbox class="example-margin" (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  
                  <td mat-cell *matCellDef="let element" class="table-dato" >
                    <mat-checkbox style="margin-left: 7px;" (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(element) : null"
                                  [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                  </td>

                </ng-container>

                <ng-container matColumnDef="select" *ngIf="!user" >
                      
                  <th mat-header-cell *matHeaderCellDef class="table-title"  >  
                    <mat-checkbox style="display: none;" class="example-margin" (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  
                  <td mat-cell *matCellDef="let element" class="table-dato border-left-none" >
                    <mat-checkbox style="display: none;" (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(element) : null"
                                  [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                  </td>

                </ng-container>

    
          </tr>
          </tbody>
        </table>
       
    </div>
    <br>
    <div class="pagination justify-content-center" style="font-size: 20px;font-weight: 600;">
      <mat-paginator class="mat-paginator" #paginator   [pageSize]="5" [length]="length" showFirstLastButtons ></mat-paginator>
    </div>
    <div class="row row-cols-11 row-cols-md-7 g-8 " style="font-size: large;" *ngIf="!prod" (ngModel)="prod">
        No exiten registros
    </div>
    
    <ng-template  #dialogTemplate >
        <video controls style="width: 800px; height: 500px;">
            <source src="{{video}}"  type="video/mp4"/>
        </video>
        <mat-dialog-actions align="end">
            <div class="modal-footer">
                <button  class="btn btn-secondary" style="width: 100px;" (click)="descargarVideo('{{video}}')"  mat-button >Descargar</button>
                <button  class="btn btn-secondary" style="width: 100px;" (click)="closeModalService()"  mat-button >Close</button>
            </div>
        </mat-dialog-actions>
    </ng-template>
      
      