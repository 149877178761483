import { APP_INITIALIZER, NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { VideosComponent } from './components/media/videos/videos.component';
import { PdfComponent } from './components/media/pdf/pdf.component';
import { ImagenesComponent } from './components/media/imagenes/imagenes.component';
import { UserComponent } from './components/user/user.component';
import { SettingsService, initSettings } from './services/settings.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorComponent } from './components/error/error.component';
import { InterceptorInterceptor } from './services/interceptor.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

//MODULES
import { MatTableModule } from '@angular/material/table' 
import { MatPaginatorIntl,MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule,MAT_DIALOG_DATA,MatDialogRef,MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatGridListModule} from '@angular/material/grid-list';
import { CarouselModule } from "@syncfusion/ej2-angular-navigations";
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from './components/loading/loading.component';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/login/register/register.component';
import { AdminComponent } from './components/admin/admin.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RecoverypassComponent } from './components/login/recoverypass/recoverypass.component'; // <- import PdfViewerModule
import { ReactiveFormsModule} from '@angular/forms';
import { GestorfilesComponent } from './components/media/gestorfiles/gestorfiles.component'; 
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; 

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VideosComponent,
    PdfComponent,
    ImagenesComponent,
    UserComponent,
    ErrorComponent,
    LoadingComponent,
    LoginComponent,
    RegisterComponent,
    AdminComponent,
    RecoverypassComponent,
    GestorfilesComponent,
    
    
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    MatGridListModule,
    CarouselModule,
    NgbCarouselModule,
    CommonModule,
    MatProgressSpinnerModule,
    BrowserModule,
    PdfViewerModule, // <- Add PdfViewerModule to imports
    MatCheckboxModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
  exports: [ LoadingComponent],  
  providers: [

    { 
      'provide': APP_INITIALIZER, 
      'useFactory': initSettings, 
      'deps': [SettingsService], 
      'multi': true, 
    }, 
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorInterceptor, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
