import { Component, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SigninRequest } from 'src/app/model/SigninRequest';
import { Usuario } from 'src/app/model/usuario';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email!: string;
  password!: string;
  request:SigninRequest;
  usuario: Usuario = new Usuario;
  loginOK=false;
  //
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date ;
  _isLogin=false;
  constructor(private userService: UserService,private idle: Idle, private keepalive: Keepalive,) {
    console.log("======== LoginComponent =======");
    this.request =  new SigninRequest();
    this.userService.currentUser.subscribe(user=>{
        console.log("|||||| actualizando currentUser:["+user.nombre+"]  LoginComponent ||||||");
        this.usuario=user;
        console.log("||||||||||||||||||||||||||||||||||||||||||||||||");
    });

    this.userService._isLogin.subscribe(isLogin=>{
        this._isLogin=isLogin;
        if(this._isLogin){
          console.log("login correcto");
        }else{
          console.log("login incorrecto");
          Swal.fire({
                title: "Atención",
                text: "login incorrecto",
                icon: "warning",
                confirmButtonColor: '#014FA2',
          });
        }
        console.log("======= this._isLogin:["+this._isLogin+"]");
    });

    this.userService.setPathLogin(true);


    console.log("==============================");
  }

  ngOnInit(): void {
  }

  
  login() {
    console.log(this.email);
    console.log(this.password);
    this.request.email=this.email;
    this.request.password=this.password;
    this.userService.login(this.request);
   
    
  }

  recoveryPass(){
    console.log("recoveryPass");
    this.userService.redirectRecoveryPass();
  }


}
