import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SigninRequest } from '../model/SigninRequest';
import { RequestFile } from '../model/requestFile';

@Injectable({
  providedIn: 'root'
})
export class ConsumeService {

  constructor(private _http: HttpClient) { }

  private _hostPortalClienteService = `${environment.uri._hostPortalVisorService}`;

  //ARCHIVOS
  private _getListFiles             = `${this._hostPortalClienteService}/documents/getListFiles`;
  private _getAllFilesNames         = `${this._hostPortalClienteService}/documents/getAllFilesNames`;
  private _getFileByName            = `${this._hostPortalClienteService}/documents/getFileByName`;
  private _putFile                  = `${this._hostPortalClienteService}/documents/putFile`;
  private _deleteFile               = `${this._hostPortalClienteService}/documents/deleteFile`;
  //PARAMETERS
  private _getLstCarpetas           = `${this._hostPortalClienteService}/initializer/getLstCarpetas`;
  //USUARIOS
  private _signin                   = `${this._hostPortalClienteService}/usuarios/auth/signin`;
   //EMAIL
   private _getRecoveryPass         = `${this._hostPortalClienteService}/email/sendRecoveryPass/`;


  //EMAIL
  getRecoveryPass(email:string) : Observable<any> {
    return this._http
    .get(`${this._getRecoveryPass}${email}`,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }

  //USUARIOS
  signin(siginData:SigninRequest) : Observable<any> {
    return this._http
    .post(`${this._signin}`,siginData,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  //PARAMETERS
  getLstCarpetas() : Observable<any> {
    return this._http
    .get(`${this._getLstCarpetas}`,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }

  //FILES
  deleteFile(requestFile:RequestFile) : Observable<any> {
    return this._http
    .post(`${this._deleteFile}`,requestFile,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }

  putFile(formData:FormData) : Observable<any> {
    return this._http
    .post(`${this._putFile}`,formData,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }
  
  getListFiles(formData:FormData) : Observable<any> {
    return this._http
    .post(`${this._getListFiles}`,formData,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }

  getListFilesNames(formData:FormData) : Observable<any> {
    return this._http
    .post(`${this._getAllFilesNames}`,formData,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }

  getFileByName(formData:FormData) : Observable<any> {
    return this._http
    .post(`${this._getFileByName}`,formData,{responseType: 'json'})
    .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    console.log(error);
    return throwError(error || 'Server error');
   }

}
